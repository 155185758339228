import { FC } from "react";
import { Outlet } from "react-router-dom";

const MapPagesWrapper: FC = () => {
  return (
    <Outlet />
  );
};

export default MapPagesWrapper;
