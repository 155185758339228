import * as Yup from "yup";

// initial values for the form
export const accountDetailsInitialValues = {
  account_id: 0,
  account_description: "",
  contact_name: "",
  contact_phone_number: "",
  contact_email: "",
  private_cost: 0.0,
  idle_gas_usage: 0.0,
  distance_gas_usage: 0.0,
  auto_update_interval_for_maps: 0,
  drivers_assigned_to_devices: false,
  enable_map_clustering: "",
  open_reports_in_new_tab: false,
  sync_driverId_from_driver_admin: false,
  has_snowplows: false,
  hide_total_rows_in_csv: false,
  timezone: "",
  speed_units: "",
  distance_units: "",
  volume_units: "",
  economy_units: "",
  pressure_units: "",
  temperature_units: "",
  latitude_longitude_format: "",
  route_segment_color_rule: "",
  route_line_thickness: "",
  multi_vehicle_map_name: "",
  device_title: "",
  device_title_plural: "",
  device_group_title: "",
  device_group_title_plural: "",
  address_title: "",
  address_title_plural: "",
  default_login_userId: "",
  default_overlay: "",
  last_maintenance_1: 0,
  last_maintenance_2: 0,
  last_maintenance_3: 0,
  last_maintenance_4: 0,
  last_maintenance_5: 0,
  last_maintenance_6: 0,
  last_maintenance_7: 0,
  last_maintenance_8: 0,
  last_maintenance_9: 0,
  last_maintenance_10: 0,
  last_eng_hours_maint_1: 0,
  last_eng_hours_maint_2: 0,
  last_eng_hours_maint_3: 0,
  last_eng_hours_maint_4: 0,
  last_eng_hours_maint_5: 0,
  last_service_time_1: 0,
  last_service_time_2: 0,
  last_service_time_3: 0,
  last_service_time_4: 0,
  last_service_time_5: 0,
  harsh_braking: "",
  harsh_acceleration: "",
  speeding: "",
  reverse: "",
  seatbelt_off: "",
  harsh_cornering: "",
  idle_ratio: "",
  impact_crash_ai: "",
  cellphone_use_ai: "",
  distracted_driving_ai: "",
  drinking_eating_ai: "",
  smoking_ai: "",
  possible_fatiuge_ai: "",
  obstructed_camera_ai: "",
  tailgating_ai: "",
}

// validation schema for the form
export const accountDetailsYupValidationSchema = Yup.object().shape({
  account_id: Yup.number().required("Account ID is required"),
  account_description: Yup.string().required("Account Description is required"),
  private_cost: Yup.number().required("Private Cost is required"),
  idle_gas_usage: Yup.number().required("Idle Gas Usage is required"),
  distance_gas_usage: Yup.number().required("Distance Gas Usage is required"),
  auto_update_interval_for_maps: Yup.number().required("Auto Update Interval For Maps is required"),
  drivers_assigned_to_devices: Yup.boolean().required("Drivers Assigned To Devices is required"),
  enable_map_clustering: Yup.string().required("Enable Map Clustering is required"),
  open_reports_in_new_tab: Yup.boolean().required("Open Reports In New Tab is required"),
  sync_driverId_from_driver_admin: Yup.boolean().required("Sync DriverId From Driver Admin is required"),
  has_snowplows: Yup.boolean().required("Has Snowplows is required"),
  hide_total_rows_in_csv: Yup.boolean().required("Hide Total Rows In CSV is required"),
  timezone: Yup.string().required("Timezone is required"),
  speed_units: Yup.string().required("Speed Units is required"),
  distance_units: Yup.string().required("Distance Units is required"),
  volume_units: Yup.string().required("Volume Units is required"),
  economy_units: Yup.string().required("Economy Units is required"),
  pressure_units: Yup.string().required("Pressure Units is required"),
  temperature_units: Yup.string().required("Temperature Units is required"),
  latitude_longitude_format: Yup.string().required("Latitude Longitude Format is required"),
  last_maintenance_1: Yup.number().required("Last Maintenance 1 is required"),
  last_maintenance_2: Yup.number().required("Last Maintenance 2 is required"),
  last_maintenance_3: Yup.number().required("Last Maintenance 3 is required"),
  last_maintenance_4: Yup.number().required("Last Maintenance 4 is required"),
  last_maintenance_5: Yup.number().required("Last Maintenance 5 is required"),
  last_maintenance_6: Yup.number().required("Last Maintenance 6 is required"),
  last_maintenance_7: Yup.number().required("Last Maintenance 7 is required"),
  last_maintenance_8: Yup.number().required("Last Maintenance 8 is required"),
  last_maintenance_9: Yup.number().required("Last Maintenance 9 is required"),
  last_maintenance_10: Yup.number().required("Last Maintenance 10 is required"),
  last_eng_hours_maint_1: Yup.number().required("Last Eng Hours Maint 1 is required"),
  last_eng_hours_maint_2: Yup.number().required("Last Eng Hours Maint 2 is required"),
  last_eng_hours_maint_3: Yup.number().required("Last Eng Hours Maint 3 is required"),
  last_eng_hours_maint_4: Yup.number().required("Last Eng Hours Maint 4 is required"),
  last_eng_hours_maint_5: Yup.number().required("Last Eng Hours Maint 5 is required"),
  last_service_time_1: Yup.number().required("Last Service Time 1 is required"),
  last_service_time_2: Yup.number().required("Last Service Time 2 is required"),
  last_service_time_3: Yup.number().required("Last Service Time 3 is required"),
  last_service_time_4: Yup.number().required("Last Service Time 4 is required"),
  last_service_time_5: Yup.number().required("Last Service Time 5 is required"),
  harsh_braking: Yup.string().required("Harsh Braking is required"),
  harsh_acceleration: Yup.string().required("Harsh Acceleration is required"),
  speeding: Yup.string().required("Speeding is required"),
  reverse: Yup.string().required("Reverse is required"),
  seatbelt_off: Yup.string().required("Seatbelt Off is required"),
  harsh_cornering: Yup.string().required("Harsh Cornering is required"),
  idle_ratio: Yup.string().required("Idle Ratio is required"),
  impact_crash_ai: Yup.string().required("Impact Crash AI is required"),
  cellphone_use_ai: Yup.string().required("Cellphone Use AI is required"),
  distracted_driving_ai: Yup.string().required("Distracted Driving AI is required"),
  drinking_eating_ai: Yup.string().required("Drinking Eating AI is required"),
  smoking_ai: Yup.string().required("Smoking AI is required"),
  possible_fatiuge_ai: Yup.string().required("Possible Fatiuge AI is required"),
  obstructed_camera_ai: Yup.string().required("Obstructed Camera AI is required"),
  tailgating_ai: Yup.string().required("Tailgating AI is required"),
});