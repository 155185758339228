export const RESPONSE_CODE = {
  UNAUTHORIZED: 401,
  SUCCESS: 200,
  CREATED: 201,
  BAD_REQUEST: 400,
  FATAL_SERVER_ERROR: 500,
  PARSING_ERROR: 'PARSING_ERROR',
  FETCH_ERROR: 'FETCH_ERROR',
};

export enum API_METHODS {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
}
